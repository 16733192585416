import React, { useState } from "react";

// ... (import statements)

const Question = ({ question, choices, correctChoice, onAnswer, feedback }) => {
  const [selectedChoice, setSelectedChoice] = useState(null);
  const feedbackMessages = [
    "Oops! That's not the correct answer. Try again.",
    "Not quite right. Keep going!",
    "Don't worry, you'll get it next time.",
    "Nice try, but that's not the answer we're looking for.",
    //istedğiğn kadar feedback koy
  ];
  const handleChoiceClick = (choiceIndex) => {
    setSelectedChoice(choiceIndex);
    onAnswer(choiceIndex === correctChoice);
  };

  return (
    <main className="bg-star w-screen h-screen flex items-center justify-center ">
      <div className="  rounded-3xl bg-forth p-4">
        <div className=" py-8 text-4xl font-komikk text-white px-4 text-center">
          {question}
        </div>
        <div className="grid md:grid-cols-2 sm:grid-cols-1 sm:gap-4 md:h-1/3 ">
          {choices.map((choice, index) => (
            <div className="flex justify-center items-center" key={index}>
              <button
                className="bg-white w-96 h-12 rounded-lg flex justify-center items-center border-2 border-primary hover:border-4 hover:border-secondary hover:bg-gray-400  transform transition-transform duration-300 hover:scale-105 "
                onClick={() => handleChoiceClick(index)}
              >
                {choice}
              </button>
            </div>
          ))}
        </div>
        <div className=" text-red-400 w-full py-4 flex justify-center items-center lg:text-2xl sm:text-lg ">
          {" "}
          {feedback}
        </div>
      </div>
    </main>
  );
};

export default Question;
