import React from "react";

import NavBar from "../../Components/NavBar";
import { BrowserRouter as Router } from "react-router-dom";
import Planet1 from "../../Images/Screenshot_2-removebg-preview.png";
import Logo from "../../Images/logo.png";
import Footer from "../../Components/Footer";
import "./a.css";
const LandingPage = () => {
  const nameData = [
    {
      name: "Tuna Zevkirlioğlu",
      title: "Founder/President",
      title2: "Head of Astronomy Group",
    },
    {
      name: "Barış Altıntaş",
      title: "Vice President",
      title2: "Head of Mathematics Group",
    },
    {
      name: "Ekin Deniz Erşan",
      title: "Social Media Manager",
    },
    {
      name: "Eray Baydemir",
      title: "IT Coordinator",
    },
    {
      name: "Seden Nalbant",
      title: "Head of Chemistry Group",
    },
    {
      name: "İnci Büyükçayır",
      title: "Head of Biology Group",
    },
    {
      name: "Berfin Ada Akan",
      title: "Head of Physics Group",
    },
  ];
  return (
    <main>
      <NavBar />
      {/* background remove */}
      <img src={Planet1} alt="asdasdas" className="w-16 h-16 " />
      <div className="bg-star">
        <section id="Home-Page">
          <div
            aria-hidden="true"
            className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
          ></div>
          <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
            <div className="relative pt-24 ml-auto">
              <div className="lg:w-2/3  mx-auto flex justify-center items-center flex-col mb-8">
                <img
                  src={Logo}
                  alt=""
                  className="text-center inline-block pb-16"
                />
                <h1 className="text-white dark:text-black font-bold font-komikk text-5xl md:text-6xl xl:text-7xl">
                  Made of Star{" "}
                  <span className="text-gray-200 dark:text-white">Stuff</span>
                </h1>
                <p className="mt-8 text-center  text-secondary dark:text-gray-300">
                  Made of Star-Stuff is a non-profit organization founded by
                  high school students who believe in equal access to education.
                  Though often overlooked, as much as financial inadequacy,
                  medical reasons are also major causes of educational
                  inequality. Many students who are bedridden or have cognitive
                  disabilities cannot access proper educational materials. We
                  aim to support such marginalized students in Turkey to succeed
                  in STEM fields by providing educational opportunities. To join
                  our team and make a difference, fill out the form below!
                </p>
                <div className=" pt-6 ">
                  <a
                    href="                  https://docs.google.com/forms/d/e/1FAIpQLSete9bE5IMM42KVfO_el33fHjWUF78UEZDpycmdDdCI9DvnEQ/viewform
"
                    className=" text-third dark:text-gray-300  hover:underline text-3xl font-komikk "
                  >
                    Link to join!{" "}
                  </a>{" "}
                </div>

                {/* Other elements go here */}
              </div>
            </div>
          </div>
        </section>
        <section id="WhoAreWe" className=" ">
          <div class=" max-w-7xl mx-auto ">
            <div className=" mx-8">
              <h1 class="text-5xl  font-komikk font-bold text-forth dark:text-white ">
                About Us
              </h1>
              <h1 class="my-4   text-3xl font-bold font-komikk text-third dark:text-white">
                We have lots to tell you!{" "}
              </h1>
            </div>
            <div className="xl:flex xl:gap-16 py-10 flex justify-between items-center sm:flex-row flex-col sm:gap-0 gap-8 ">
              <div class="md:w-2/3 lg:w-1/2 px-8">
                <p class="text-gray-400 dark:text-gray-400">
                  At Made of Star Stuff, we believe in the unlimited potential
                  of young minds and the transformative power of education.
                  Founded on a passion for knowledge and a commitment to
                  outreach, our organization has become a beacon of learning
                  that makes math and science not only accessible but also
                  irresistible to children. Our mission is based on a simple yet
                  profound purpose: to provide free, quality education in math
                  and science to emerging children. We recognize that exposure
                  to these topics at an early age is critical to foster
                  curiosity, critical thinking, and a lifelong love of learning.
                  That's why we toured classrooms and hospital wards, giving
                  fascinating lessons that turned complex concepts into
                  kid-friendly adventures. Our pride is a specially prepared
                  book that introduces the wonders of aviation in terms that
                  children can grasp and adults can appreciate. With each page,
                  we aim to elevate young dreams and take them on a journey
                  through the vastness and complexity of the universe.
                </p>
              </div>
              <div class="md:w-2/3 lg:w-1/2 px-8">
                <p class="text-gray-400 dark:text-gray-400">
                  Our team of educators, scientists and volunteers are working
                  tirelessly to expand our reach. We've designed interactive
                  workshops, hands-on activities and immersive experiences that
                  bring the excitement of discovery to the heart of education.
                  As we grow, our connection to the community deepens through
                  the shared belief that every child, like every star, is unique
                  and has the potential to light up the world. Join us at Made
                  of Star Stuff where we continue to inspire, educate and guide
                  the explorers of tomorrow. Because when we invest in our
                  children's minds, we not only give them knowledge about the
                  world, we provide them with the tools to reach the stars.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section id="Club">
        <div class="text-gray-600 dark:text-gray-300" id="testimonials">
          <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
            <div class="mb-20 space-y-4 px-6 md:px-0">
              <h2 class="text-center text-2xl my-16 font-bold text-gray-800 dark:text-white md:text-4xl">
                Meet our team!{" "}
              </h2>
            </div>
            <div class=" grid lg:grid-cols-3  sm:grid-cols-1 gap-8 space-y-8">
              {nameData.map((item) => (
                <div class=" p-8 border border-gray-100 rounded-3xl bg-white dark:bg-gray-800 dark:border-gray-700 shadow-2xl shadow-gray-600/10 dark:shadow-none">
                  <div class="flex gap-4">
                    <div>
                      <h6 class="text-lg font-medium text-gray-700 dark:text-white">
                        {item.name}{" "}
                      </h6>
                      <p class="text-sm text-gray-500 dark:text-gray-300">
                        {item.title}
                      </p>
                      <p class="text-sm text-gray-500 dark:text-gray-300">
                        {item.title2}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section id="Articles">
        <div id="blog">
          <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
            <div class="mb-12 space-y-2 text-center">
              <h2 class="text-3xl py-8 font-bold text-gray-800 md:text-4xl ">
                Articles: Coming Soon!
              </h2>
              <p class="lg:mx-auto lg:w-6/12 text-gray-600 dark:text-gray-300 text-6xl py-4 font-komikk text-bold"></p>
            </div>
            {/* <div class="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              <div class="group p-6 sm:p-8 rounded-3xl bg-white border border-gray-100 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 bg-opacity-50 shadow-2xl shadow-gray-600/10">
                <div class="relative overflow-hidden rounded-xl">
                  <img
                    src="https://images.unsplash.com/photo-1661749711934-492cd19a25c3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80"
                    alt="art cover"
                    loading="lazy"
                    width="1000"
                    height="667"
                    class="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105"
                  />
                </div>
                <div class="mt-6 relative">
                  <h3 class="text-2xl font-semibold text-gray-800 dark:text-white">
                    De fuga fugiat lorem ispum laboriosam expedita.
                  </h3>
                  <p class="mt-6 mb-8 text-gray-600 dark:text-gray-300">
                    Voluptates harum aliquam totam, doloribus eum impedit atque!
                    Temporibus...
                  </p>
                  <a class="inline-block" href="#">
                    <span class="text-info dark:text-blue-300">Read more</span>
                  </a>
                </div>
              </div>
              <div class="group p-6 sm:p-8 rounded-3xl bg-white border border-gray-100 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 bg-opacity-50 shadow-2xl shadow-gray-600/10">
                <div class="relative overflow-hidden rounded-xl">
                  <img
                    src="https://images.unsplash.com/photo-1491895200222-0fc4a4c35e18?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80"
                    alt="art cover"
                    loading="lazy"
                    width="1000"
                    height="667"
                    class="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105"
                  />
                </div>
                <div class="mt-6 relative">
                  <h3 class="text-2xl font-semibold text-gray-800 dark:text-white">
                    De fuga fugiat lorem ispum laboriosam expedita.
                  </h3>
                  <p class="mt-6 mb-8 text-gray-600 dark:text-gray-300">
                    Voluptates harum aliquam totam, doloribus eum impedit atque!
                    Temporibus...
                  </p>
                  <a class="inline-block" href="#">
                    <span class="text-info dark:text-blue-300">Read more</span>
                  </a>
                </div>
              </div>
              <div class="group p-6 sm:p-8 rounded-3xl bg-white border border-gray-100 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 bg-opacity-50 shadow-2xl shadow-gray-600/10">
                <div class="relative overflow-hidden rounded-xl">
                  <img
                    src="https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
                    alt="art cover"
                    loading="lazy"
                    width="1000"
                    height="667"
                    class="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105"
                  />
                </div>
                <div class="mt-6 relative">
                  <h3 class="text-2xl font-semibold text-gray-800 dark:text-white">
                    De fuga fugiat lorem ispum laboriosam expedita.
                  </h3>
                  <p class="mt-6 mb-8 text-gray-600 dark:text-gray-300">
                    Voluptates harum aliquam totam, doloribus eum impedit atque!
                    Temporibus...
                  </p>
                  <a class="inline-block" href="#">
                    <span class="text-info dark:text-blue-300">Read more</span>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </main>
  );
};

export default LandingPage;
