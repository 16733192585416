import "./App.css";
import NavBar from "./Components/NavBar";
import {
  BrowserRouter,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Planet1 from "./Images/Screenshot_2-removebg-preview.png";
import LandingPage from "./Screens/LandingPage/LandingPage";
import GamePage from "./Screens/GamePage/GamePage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/quiz_game" element={<GamePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
