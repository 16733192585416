import React, { useState } from "react";
import Question from "./Question";
import Planet1 from "../../Images/Screenshot_2-removebg-preview.png";
const GamePage = () => {
  const questions = [
    {
      question: "1. What is the Sun?",
      choices: ["A planet", "A comet", "A star", "A moon"],
      correctChoice: 2,
    },
    {
      question: "2. What planet is known as the Red Planet?",
      choices: ["Venus", "Mars", "Jupiter", "Saturn"],
      correctChoice: 1,
    },
    {
      question: "3. Which planet is known for its rings?",
      choices: ["Mercury", "Earth", " Uranus", "Saturn"],
      correctChoice: 3,
    },
    {
      question: "4. What is Earth’s only natural satellite?",
      choices: ["The Sun", "Mars", "The Moon", "Venus"],
      correctChoice: 2,
    },
    {
      question:
        "5. What is a group of stars that forms a pattern in the sky called?",
      choices: ["A meteor", "A galaxy", "A constellation", "A comet"],
      correctChoice: 2,
    },
    {
      question: "6. Which planet is known as the Blue Planet?",
      choices: ["Earth", "Neptune", "Uranus", "Mercury"],
      correctChoice: 0,
    },
    {
      question: "7. What galaxy is Earth located in?",
      choices: [
        "The Milky Way Galaxy",
        "The Andromeda Galaxy",
        "The Whirlpool Galaxy",
        "The Black Eye Galaxy",
      ],
      correctChoice: 0,
    },
    {
      question: "9. Which of these objects orbits the Earth?",
      choices: ["The Sun", "The Moon", "Mars", "Venus"],
      correctChoice: 1,
    },
    {
      question:
        "10. What do we call a scientist who studies the stars and planets?",
      choices: ["Chemist", "Biologist", "Astronomer", "Geologist"],
      correctChoice: 2,
    },

    //koy istediğin kadar soru
  ];
  const feedbackMessages = [
    "Oops! That's not the correct answer. Try again.",
    "Not quite right. Keep going!",
    "Don't worry, you'll get it next time.",
    "Nice try, but that's not the answer we're looking for.",
    //istedğiğn kadar feedback koy
  ];
  const handleRestart = () => {
    // Reset all game-related states
    setCurrentQuestionIndex(0);
    setCorrectAnswers(0);
    setWrongAnswers(0);
    setFeedback("");
    setFinished(false);
  };

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [wrongAnswers, setWrongAnswers] = useState(0);
  const [finished, setFinished] = useState(false);
  const [feedback, setFeedback] = useState("");

  const handleAnswer = (isCorrect) => {
    if (isCorrect) {
      setCorrectAnswers(correctAnswers + 1);
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setFeedback("");
      } else {
        setFinished(true);
      }
    } else {
      setWrongAnswers(wrongAnswers + 1);
      const randomIndex = Math.floor(Math.random() * feedbackMessages.length);
      setFeedback(feedbackMessages[randomIndex]);
    }

    // Move to the next question

    // Wait for 1 second before showing the next question
  };

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="bg-star">
      {!finished ? (
        <>
          <Question
            question={currentQuestion.question}
            choices={currentQuestion.choices}
            correctChoice={currentQuestion.correctChoice}
            onAnswer={handleAnswer}
            feedback={feedback}
          />
        </>
      ) : (
        <main className="flex justify-center items-center h-screen w-screen">
          <div className="bg-forth h-4/5 w-4/5 rounded-3xl">
            <div className="text-5xl text-white text-center md:h-2/3 sm:h-1/2 w-auto py-32">
              Correct Answers: {correctAnswers} Wrong Answers: {wrongAnswers}
            </div>
            <div className="w-full">
              <button
                className="h-12 w-48 bg-white rounded-lg mx-auto block"
                onClick={handleRestart}
              >
                Restart the game
              </button>
            </div>
          </div>
        </main>
      )}

      {/* Add result display logic here */}
    </div>
  );
};

export default GamePage;
